<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize" @submit.native.prevent>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getMerchantSel" @change="onSearch">
            <el-option v-for="item in merchantArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:image="{row}">
        <el-image
          style="width: 60px; height: 60px"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]"></el-image>
      </template>
      <template v-slot:merchant_list="{row}">
        <el-tooltip placement="top" effect="light" v-if="row.merchant_list.length > 0">
          <div slot="content">
            <span v-for="(item,index) in row.merchant_list" :key="item.id">
              <span>{{item.name}}</span>
              <span v-if="index < row.merchant_list.length - 1">、</span>
            </span>
          </div>
          <el-link style="padding: 0 10px;" type="primary">{{row.merchant_list.length}}</el-link>
        </el-tooltip>
        <span v-else style="padding: 0 10px;">{{row.merchant_list.length}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">绑定客户</el-button>
      </template>
    </VTable>

    <el-dialog title="" width="500px" :visible.sync="dialogFormVisible">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
        >
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="form.data.merchant_id" multiple filterable clearable placeholder="请选择">
            <el-option v-for="item in merchantArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <div></div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="form.loading" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'TempletIndex',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "name", label: "模板名称",width:'120', showTooltip: true, hidden: false},
        { name: "image", label: "样式预览", width:'120', hidden: false},
        { name: "merchant_list", label: "绑定客户",width:'100', hidden: false},
        { name: "desc", label: "模板说明", hidden: false},
        { name: "create_time", label: "创建时间", width:'160', hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          merchant_id:'',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      merchantArr: [],
      dialogFormVisible: false,
      form: {
        loading: false,
        data: {
          id: '',
          merchant_id: []
        },
        rules: {
          // merchant_id: [{ required: true, message:'必填项', trigger: ['change','blur']}],
        }
      }
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/excel/list', {params:this.table.params} ).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      this.getMerchantSel(); // 获取客户
      this.$nextTick(() => {
        this.dialogFormVisible = true;
        this.form.data.id = row.id;
        this.form.data.merchant_id = row.merchant_ids == '' ? [] : row.merchant_ids.split(",").map(Number)
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if (valid) {
          let _params = {
            id: this.form.data.id,
            merchant_ids: this.form.data.merchant_id.join(",")
          }
          this.form.loading = true;
          this.$http.post('/admin/excel/bind',_params).then(res => {
            if(res.code == 1) {
              this.$message.success("操作成功")
              this.getTable();
              this.dialogFormVisible = false;
              this.form.data.merchant_ids = []
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    }
  }
}
</script>
